<template>
  <div>
    <div class="addPromotionbox">
      <div class="titlebox">
        <span class="marginleft10" @click="toOptimalWill"
          ><i class="el-icon-arrow-left"></i>优享会</span
        >
        <span> - 加入优享会</span>
      </div>
      <div class="bgbox">
        <div class="zhanwei1"></div>
        <div class="bgboxtitle">优享会</div>
        <div class="bgboxlabel">
          方便使用，以更丰富/灵活的折扣吸引更多忠诚用户
        </div>
      </div>

      <div class="boxone">
        <div class="boxoneitembox">
          <div class="boxoneimgbox">
            <img src="../../assets/imgs/optimalwill957.png" alt="" />
          </div>
          <div class="boxoneimgrightbox">
            <div class="zhanwei2"></div>
            <div class="title2">提升排序加权</div>
            <div class="label2">提高酒店排序位，赢在挂牌起跑线</div>
          </div>
        </div>
        <div class="boxoneitembox">
          <div class="boxoneimgbox">
            <img src="../../assets/imgs/optimalwill978.png" alt="" />
          </div>
          <div class="boxoneimgrightbox">
            <div class="zhanwei2"></div>
            <div class="title2">额外流量曝光</div>
            <div class="label2">增加流量入口，强化用户感知</div>
          </div>
        </div>
        <div class="boxoneitembox">
          <div class="boxoneimgbox">
            <img src="../../assets/imgs/optimalwill979.png" alt="" />
          </div>
          <div class="boxoneimgrightbox">
            <div class="zhanwei2"></div>
            <div class="title2">定位高端用户</div>
            <div class="label2">服务优质会员，提升用户转化</div>
          </div>
        </div>
      </div>
      <div class="boxtwo">
        <span class="boxtwotitle">价格折扣</span>
        <span class="boxtwolabel1">
          折扣上限为白银9.5折，黄金9.0折，铂金9.0折，钻石8.5折。给予低级别会员的折扣不可高于高级别会员。
        </span>
        <span class="boxtwolabel2"> 仅大陆酒店可以报名白银贵宾 </span>
      </div>
      <div class="boxthree">
        <div class="boxthreeitembox">
          <img
            class="boxthreeimg"
            src="../../assets/imgs/optimalwill982.png"
            alt=""
          />
          <div class="boxthreeinlinebox">
            <span class="boxthreeitemtitle"> 钻石及以上 </span>
            <el-select
              size="mini"
              v-model="handData.zcostValue"
              class="width140"
            >
              <el-option
                v-for="item in optionsList1"
                :key="item.id"
                :value="item.value"
                :label="item.label"
              >
              </el-option>
            </el-select>
          </div>
        </div>

        <div class="boxthreeitembox">
          <img
            class="boxthreeimg"
            src="../../assets/imgs/optimalwill983.png"
            alt=""
          />
          <div class="boxthreeinlinebox">
            <span class="boxthreeitemtitle"> 铂金贵宾 </span>
            <el-select
              size="mini"
              v-model="handData.bcostValue"
              class="width140"
            >
              <el-option
                v-for="item in optionsList2"
                :key="item.id"
                :value="item.value"
                :label="item.label"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="boxthreeitembox">
          <img
            class="boxthreeimg"
            src="../../assets/imgs/optimalwill981.png"
            alt=""
          />
          <div class="boxthreeinlinebox">
            <span class="boxthreeitemtitle"> 黄金贵宾 </span>
            <el-select
              size="mini"
              v-model="handData.hcostValue"
              class="width140"
            >
              <el-option
                v-for="item in optionsList2"
                :key="item.id"
                :value="item.value"
                :label="item.label"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="boxthreeitembox">
          <img
            class="boxthreeimg"
            src="../../assets/imgs/optimalwill984.png"
            alt=""
          />
          <div class="boxthreeinlinebox">
            <span class="boxthreeitemtitle"> 银牌贵宾 </span>
            <el-select
              size="mini"
              v-model="handData.costValue"
              class="width140"
            >
              <el-option
                v-for="item in optionsList3"
                :key="item.id"
                :value="item.value"
                :label="item.label"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="titleboxtwo" v-if="submitName=='参加'">选择加入的酒店</div>
      <div class="hotellistbox" v-if="submitName=='参加'" >
        <div>
          <div class="checkOne">
            <span>城市</span>
            <el-input
              size="mini"
              v-model="searchData.city"
              style="width: 110px; margin-left: 10px; background-color: #f6f8fb"
              placeholder="城市名"
            ></el-input>
          </div>
          <div class="checkOne">
            <el-select size="mini" style="width: 100px" v-model="hotelNameorId">
              <el-option label="酒店名称" value="酒店名称"></el-option>
              <el-option label="酒店ID" value="酒店ID"></el-option>
            </el-select>

            <el-input
              v-show="hotelNameorId == '酒店名称'"
              size="mini"
              style="width: 180px; margin-left: 10px"
              v-model="searchData.hotelName"
              placeholder="请输入酒店名称"
            >
            </el-input>
            <el-input
              v-show="hotelNameorId == '酒店ID'"
              size="mini"
              style="width: 180px; margin-left: 10px"
              v-model="searchData.hotelName"
              placeholder="请输入酒店ID"
            >
            </el-input>
          </div>
          <div class="checkOne">
            <el-select size="mini" style="width: 100px" v-model="roomNameorId">
              <el-option label="房型名称" value="房型名称"></el-option>
              <el-option label="房型ID" value="房型ID"></el-option>
            </el-select>
            <el-input
              v-show="roomNameorId == '房型名称'"
              size="mini"
              style="width: 180px; margin-left: 10px"
              v-model="searchData.roomName"
              placeholder="请输入房型名称"
            >
            </el-input>
            <el-input
              v-show="roomNameorId == '房型ID'"
              size="mini"
              style="width: 180px; margin-left: 10px"
              v-model="searchData.roomName"
              placeholder="请输入房型ID"
            >
            </el-input>
          </div>
          <el-button type="primary" @click="searchHotelTableFn" size="mini"
            >搜索</el-button
          >
          <div class="roomBoxOneOff" @click="setTableSwitch">
            {{ tableSwitch }}
          </div>
        </div>
        <div v-if="tableSwitch == '列表收起'" class="roomBoxOneHotelNameBox">
          <div
            class="roomBoxOneHotelName"
            :style="{ color: checkedColor == item.hotelId ? '#256DEF' : '' }"
            @click="searchHotelInfo(item.hotelId, index, item.hotelName)"
            v-for="(item, index) in hotelList"
            :key="item.hotelId"
          >
            <span style="margin-left: 10px">
              {{ item.hotelName | formatHotelName() }}
            </span>
          </div>
          <el-pagination
            v-if="total > 15"
            class="roomPagin"
            background
            small
            @current-change="handleCurrentChange"
            :page-size="15"
            layout="prev, pager, next"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
      <div class="titleboxtwo" style="color: #256def">
        {{ handData.hotelName }}
      </div>
      <div>
        <div class="titleboxtwo inlineblockbox marginright10">关联修改</div>
        <div class="inlineblockbox margintop30">
          <div
            class="inlineblockbox marginright10"
            v-for="item in pmVestList"
            :key="item.hotelVestId"
          >
            <el-checkbox
              @change="handleCheckVestChange(item, $event)"
              v-model="item.checked"
              >{{ item.horseName }}</el-checkbox
            >
          </div>
        </div>
      </div>
      <div class="titleboxtwo">
        <el-button type="primary" @click="addOptimalWill">{{
          submitName
        }}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { hotelList_api } from "../../apis/room";
import {
  getPmRoomList_api,
  addOptimalWill_api,
  getPromotionMember_api,
} from "../../apis/hotelPackage";
import local from "@/utils/local.js";
export default {
  data() {
    return {
      optionsList1: [
        { id: 1, value: "0.85", label: "8.5折(15% OFF)" },
        { id: 2, value: "0.8", label: "8.0折(20% OFF)" },
        { id: 3, value: "0.75", label: "7.5折(25% OFF)" },
        { id: 4, value: "0.7", label: "7.0折(30% OFF)" },
        { id: 5, value: "0.65", label: "6.5折(35% OFF)" },
        { id: 6, value: "0.6", label: "6.0折(40% OFF)" },
        { id: 7, value: "0.55", label: "5.5折(45% OFF)" },
        { id: 8, value: "0.5", label: "5.0折(50% OFF)" },
      ],
      optionsList2: [
        { id: 0, value: "0.9", label: "9.0折(10% OFF)" },
        { id: 1, value: "0.85", label: "8.5折(15% OFF)" },
        { id: 2, value: "0.8", label: "8.0折(20% OFF)" },
        { id: 3, value: "0.75", label: "7.5折(25% OFF)" },
        { id: 4, value: "0.7", label: "7.0折(30% OFF)" },
        { id: 5, value: "0.65", label: "6.5折(35% OFF)" },
        { id: 6, value: "0.6", label: "6.0折(40% OFF)" },
        { id: 7, value: "0.55", label: "5.5折(45% OFF)" },
        { id: 8, value: "0.5", label: "5.0折(50% OFF)" },
      ],
      optionsList3: [
        { id: 9, value: "0.95", label: "9.5折(5% OFF)" },
        { id: 0, value: "0.9", label: "9.0折(10% OFF)" },
        { id: 1, value: "0.85", label: "8.5折(15% OFF)" },
        { id: 2, value: "0.8", label: "8.0折(20% OFF)" },
        { id: 3, value: "0.75", label: "7.5折(25% OFF)" },
        { id: 4, value: "0.7", label: "7.0折(30% OFF)" },
        { id: 5, value: "0.65", label: "6.5折(35% OFF)" },
        { id: 6, value: "0.6", label: "6.0折(40% OFF)" },
        { id: 7, value: "0.55", label: "5.5折(45% OFF)" },
        { id: 8, value: "0.5", label: "5.0折(50% OFF)" },
      ],
      value: "",
      searchData: {
        city: "",
        hotelName: "",
        hotelId: "",
        roomName: "",
        roomId: "",
        page: 1,
        rows: "15",
      },

      hotelNameorId: "酒店名称",
      roomNameorId: "房型ID",
      hotelList: [],
      total: 0,
      tableSwitch: "列表收起",
      checkedColor: 0,

      pmVestList: [],
      handData: {
        hotelSonId: "",
        hotelName: "",
        id: "",
        hotelVestIds: "",
        state: 1, //状态
        zcostValue: "", //钻石
        bcostValue: "", //铂金
        hcostValue: "", //黄金
        costValue: "", //白银
      },
      submitName: "参加",
     
    };
  },
  created() {
    if (this.$route.query.pid) {
      this.handData.id = this.$route.query.pid;
      this.getPromotionMember();
     this.submitName='修改'
    } else {
      this.handData = {
        hotelSonId: "",
        hotelName: "",
        id: "",
        hotelVestIds: "",
        state: 1, //状态
        zcostValue: "", //钻石
        bcostValue: "", //铂金
        hcostValue: "", //黄金
        costValue: "", //白银
      };
       this.searchHotelTableFn();
    }
   
  },
  filters: {
    formatHotelName(val) {
      if (val.length < 20) {
        return val;
      } else {
        let newstr = val.substring(0, 16);
        return newstr + "...";
      }
    },
  },
  methods: {
    toOptimalWill() {
      this.$router.push({
        path: "/optimalWill",
      });
    },
    async getPromotionMember() {
      let { code, data } = await getPromotionMember_api({
        pid: this.handData.id,
      });
      if (code == 0) {
        this.handData = data;

         data.vest.forEach((item) => {
          if (item.isCk == 0) {
            this.$set(item, "checked", false);
          } else if (item.isCk == 1) {
            this.$set(item, "checked", true);
          }
        });
        this.pmVestList = data.vest;

        // this.getHotelInfo(this.handData.hotelSonId);
      }
    },
    searchHotelTableFn() {
      local.remove("roomPageHotelId");
      this.searchData.page = 1;
      this.searchHotelTable();
    },
    //根据条件查询酒店列表
    async searchHotelTable() {
      let { code, data } = await hotelList_api(this.searchData);
      if (code == 0) {
        this.hotelList = data.records;
        this.total = data.total;
        if (local.get("roomPageHotelId")) {
          this.getHotelInfo(local.get("roomPageHotelId"));
        } else {
          if (this.hotelList.length > 0) {
            this.searchHotelInfo(
              this.hotelList[0].hotelId,
              0,
              this.hotelList[0].hotelName
            );
          }
        }
      }
    },
    //酒店列表分页
    handleCurrentChange(val) {
      // console.log(val);
      this.searchData.page = val;
      this.searchHotelTable();
    },
    //列表开关
    setTableSwitch() {
      if (this.tableSwitch == "列表收起") {
        this.tableSwitch = "列表展开";
      } else {
        this.tableSwitch = "列表收起";
      }
    },
    searchHotelInfo(id, index, hotelName) {
      local.set("roomPageHotelId", id);
      this.checkedColor = id;
      this.handData.hotelName = hotelName;
      this.getHotelInfo(id);
    },
    async getHotelInfo(id) {
      let { code, data } = await getPmRoomList_api({
        hotelSonId: id,
      });
      if (code == 0) {
        this.handData.hotelSonId = id;

        data.vest.forEach((item) => {
          if (item.isCk == 0) {
            this.$set(item, "checked", false);
          } else if (item.isCk == 1) {
            //  count++;
            this.$set(item, "checked", true);
          }
        });
        this.pmVestList = data.vest;

      }
    },
    //关联修改
    handleCheckVestChange(item, e) {
      item.checked = e;
    },
    addOptimalWill() {
      //促销渠道  关联修改
      let vestIdsList = [];
      this.pmVestList.forEach((item) => {
        if (item.checked == true) {
          vestIdsList.push(item.hotelVestId);
        }
      });
      //   if (
      //     !this.handData.zcostValue ||
      //     !this.handData.bcostValue ||
      //     !this.handData.hcostValue ||
      //     !this.handData.costValue
      //   ) {
      //     this.$message.error("请选择各段位折扣");
      //   } else
      if (vestIdsList.length == 0) {
        this.$message.error("请选择促销渠道");
      } else {
        this.handData.hotelVestIds = vestIdsList.join(",");
        this.addOptimalWillFn();
      }
    },
    async addOptimalWillFn() {
      let { code, data } = await addOptimalWill_api(this.handData);
      if (code == 0) {
        this.$message.success(`成功${this.submitName}此优享会`);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.addPromotionbox {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);
  border-radius: 10px;
  padding-bottom: 20px;
}
.titlebox {
  width: 100%;
  height: 40px;
  line-height: 40px;
  background: #256def;
  border-radius: 10px 10px 0px 0px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #fafcff;
}
.marginleft10 {
  margin-left: 10px;
  cursor: pointer;
}
.bgbox {
  width: 100%;
  height: 304px;
  background-image: url("../../assets/imgs/optimalwillbg.png");
  background-size: 1200px 304px;
}
.zhanwei1 {
  width: 100%;
  height: 130px;
}
.bgboxtitle {
  margin-left: 90px;
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #fafcff;
}
.bgboxlabel {
  margin-top: 20px;
  margin-left: 90px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #fafcff;
}
.boxone {
  width: 1120px;
  margin: 0 auto;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.boxoneitembox {
  width: 260px;
  height: 100px;
  display: flex;
  justify-content: space-between;
}
.boxoneimgbox {
  width: 100px;
  height: 100px;
  img {
    width: 100px;
    height: 100px;
  }
}
.boxoneimgrightbox {
  width: 140px;
  height: 100px;
}
.zhanwei2 {
  width: 100%;
  height: 20px;
}
.title2 {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #566980;
}
.label2 {
  margin-top: 10px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  line-height: 21px;
}
.boxtwo {
  margin-top: 30px;
  margin-left: 20px;
}
.boxtwotitle {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  margin-right: 10px;
}
.boxtwolabel1 {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  margin-right: 10px;
}
.boxtwolabel2 {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #f03434;
}
.boxthree {
  width: 1160px;
  margin: 0 auto;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.boxthreeitembox {
  height: 68px;
  line-height: 68px;
}
.boxthreeinlinebox {
  height: 68px;
  line-height: 68px;
  display: inline-block;
  vertical-align: top;
}
.boxthreeimg {
  width: 48px;
  height: 66px;
  display: inline-block;
}
.width140 {
  width: 140px;
  display: inline-block;
}
.boxthreeitemtitle {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  margin-left: 10px;
  margin-right: 10px;
}
.titleboxtwo {
  margin-left: 20px;
  margin-top: 30px;
  margin-bottom: 20px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.hotellistbox {
  margin: 0 auto;
  width: 1120px;
  padding: 20px;
  background: #f1f5fb;
  border-radius: 10px;
  position: relative;
}
.checkOne {
  display: inline-block;
  margin-right: 40px;
  margin-bottom: 20px;
}
.roomBoxOneOff {
  width: 56px;
  height: 28px;
  line-height: 28px;
  color: #256ef1;
  cursor: pointer;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  //   display: inline-block;
  float: right;
}
.roomBoxOneHotelNameBox {
  width: 100%;
  background-color: #f1f5fb;
}
.roomBoxOneHotelName {
  display: inline-block;
  cursor: pointer;
  width: 20%;
  margin: 10px auto;
  //text-align: center;
}
.roomBoxOneHotelName:hover {
  color: #256def;
}
.roomPagin {
  text-align: right;
}
.inlineblockbox {
  display: inline-block;
  vertical-align: top;
}
.marginright10 {
  margin-right: 10px;
}
.margintop30 {
  margin-top: 30px;
}
</style>

